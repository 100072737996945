<template>
  <v-navigation-drawer 
  location="left" 
  v-model="store.state.drawer" 
  id="MenuNavi"
  disable-resize-watcher 
  >

    <v-list density="compact" nav class="mt-4">
      <v-menu v-for="(section, index) in sections" :key="index">
        <template v-slot:activator="{ props }">
          <v-list-item style=" padding: 0 25px 0 35px !important;"
            @click="toggleDrawer"
            v-bind="props"
            :to="`/${section.link.toLowerCase()}`"
            :class="{ 'v-btn--active': isActive(section.link) }"
            class="activeClick">
            <v-icon class="menuNaviIcon" style="margin-bottom: 3px; margin-right: 25px;">{{ section.prependIcon }}</v-icon>
            {{ section.title }}
          </v-list-item>
        </template>
      </v-menu>
    </v-list>
    <template v-slot:append>
    </template>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import router from '@/router';
import {
  mdiInformationSlabCircleOutline,
  mdiBriefcaseOutline,
  mdiMonitorAccount,
  mdiCurrencyKrw  ,
  mdiHomeOutline,
  mdiClipboardEditOutline,
  mdiNotebookOutline,
  mdiFinance,
  mdiAccountCircleOutline

} from '@mdi/js'
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
const store = useStore();

const toggleDrawer = () => {
  // store.commit('setDrawer', !store.state.drawer);
  console.log(store.state.drawer)
};
let sections = ref([]) as any;
const isActive = (link: any) => {
  const currentPath = router.currentRoute.value.path.toLowerCase();
  const includes = currentPath.includes(`/${link.toLowerCase()}`);
  return includes;
};
const sectionsMap = {
  '근태관리': [
    { title: 'HOME', link: 'WorkMain', prependIcon: mdiHomeOutline },
    { title: '근태정보', link: 'SsEmpList', prependIcon: mdiInformationSlabCircleOutline },
    { title: '근태관리', link: 'SsWork', prependIcon: mdiBriefcaseOutline },
    { title: '근태기초', link: 'SsAttendance', prependIcon: mdiMonitorAccount },
    { title: '근태대장', link: 'SsWage', prependIcon: mdiCurrencyKrw },
  ],
  '임금관리': [
    { title: 'HOME', link: 'WageMain', prependIcon: mdiHomeOutline },
    { title: '사원관리', link: 'EmpInfo', prependIcon: mdiAccountCircleOutline },
    { title: '임금기초', link: 'WageBasic', prependIcon: mdiClipboardEditOutline },
    { title: '임금대장', link: 'WageLedger', prependIcon: mdiNotebookOutline },
  ],
  '재무회계': [
    { title: 'HOME', link: 'FnaMain', prependIcon: mdiHomeOutline },
    { title: '현금출납부', link: 'MngInout', prependIcon: mdiCurrencyKrw },
    { title: '예산관리', link: 'MngBudgetList', prependIcon: mdiFinance },
    { title: 'w4c 매크로', link: 'TestOne', prependIcon: mdiCurrencyKrw },
  ],
  '위탁급식': [
    { title: 'HOME', link: 'MealMain', prependIcon: mdiHomeOutline },
    { title: '급식관리', link: '', prependIcon: mdiInformationSlabCircleOutline },
  ],
} as any;

watch(
  () => store.state.AdminService,
  (newValue) => {
    if (newValue) {
      sections.value = sectionsMap[newValue] || [];
    }
  }
);

onMounted(() => {
  const newValue = store.state.AdminService;
  if (newValue) {
    sections.value = sectionsMap[newValue] || [];
  }
});
</script>