<template>
  <v-btn @click="dialogOpen()" class="btnColorPurple">전월 내역 불러오기</v-btn>
  <v-dialog v-model="dialog"  width="450px" class="fontNotoSans400">
    <v-card>
      <v-card-text>
        <div v-if="loading" class="spinner tac"></div>
        <v-data-table no-data-text="데이터가 존재하지 않습니다." v-else fixed-header density="compact" scrollable :headers="headersArr" :items="empList"
          item-value="name" class="SsTb fontNotoSans400" style="
            height: calc(100vh - 200px); 
            max-height: 600px;
            overflow-y: hidden; 
            position: relative;
          " :items-per-page="50" :items-per-page-options="[
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: 200, title: '200' }
              // { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
            ]">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="fontNotoSans700" style="max-width: 200px;">
                <v-btn v-if="(selectEmp.length !== empList.length)" @click="allSelectEmp" class="btnColorWhite" style="width: 100px;">전체체크</v-btn>
                <v-btn v-else-if="(selectEmp.length === empList.length) && empList.length" @click="allSelectEmp" class="btnColorWhite" style="width: 100px;">전체체크해제</v-btn>
              </v-toolbar-title>
              <div class="tac">
                <p>적용될 연월</p><p class="btnColorPink">{{ store.state.AdminYYMM }}</p>
              </div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <div class="tac mr-4">
                <p>불러올 연월</p>
                <p class="btnColorPurple" style="width: auto;">{{ currentMonthDisplay }}</p>

            </div>

            </v-toolbar>
          </template>
          <template v-slot:item="{ item, index }">
            <tr class="SsTbTr">
              <td class="tal" style="display: flex; justify-content: left; align-items: center;">
                <v-checkbox style="display: inline-block;" v-model="selectEmp" :value="item.emp_num" color="secondary"
                  hide-details></v-checkbox>
                <span>{{ index + 1 }}.</span>
              </td>
              <td class="tac">{{ item.emp_num }}</td>
              <td class="tac">{{ item.emp_name }}</td>
              <td class="tac">{{ item.emp_job }}</td>
            </tr>
          </template>
        </v-data-table>
        <v-btn style="width: 100%;" class="btnColorPurple mb-1" @click="sendEmpList()">불러오기</v-btn>
        <v-btn style="width: 100%;" class="btnColorDark" @click="notSelectEmp()">취소</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { computed, ref, watch } from 'vue';
import { format, parse, subMonths } from 'date-fns';

const dialog = ref(false);
const code = ref(store.state.AdminSisulCode) as any;

const parsedDate  = ref(parse(store.state.AdminYYMM, 'yyyy-MM', new Date()));
const oneMonthAgo = subMonths(parsedDate.value, 1);
const currentDate = ref(oneMonthAgo);
const date = ref(format(currentDate.value, 'yyyy-MM'));

const currentMonthDisplay = computed(() => format(currentDate.value, 'yyyy-MM'));

// eslint-disable-next-line no-undef
const emit = defineEmits(['success'])
const loading = ref(false);
const empList = ref([] as any[])
const selectEmp = ref<string[]>([]);
const dialogOpen = async () => {
dialog.value = true;
selectEmp.value = [];
await loadEmpList();
}
const headersArr = ref([
{ title: 'No.', key: 'index', align: 'center', sortable: false },
{ title: '사원번호', key: 'emp_num', align: 'center', sortable: true },
{ title: '이름', key: 'emp_name', align: 'center', sortable: true },
{ title: '직위', key: 'emp_job', align: 'center', sortable: true },
] as any[]);

const allSelectEmp = () => {
if(selectEmp.value.length === empList.value.length){
  selectEmp.value = [];
}else{
  selectEmp.value = empList.value.map(emp => emp.emp_num);
}
};
const notSelectEmp = () => {
dialog.value = false;
};
watch(
() => store.state.AdminYYMM,
(newValue) => {
  if (newValue) {
    date.value = newValue;
  }
}
);
const formatYm = (value:any) => {
    if (!value) return '';
    const [year, month] = value.split('-');
    return `${year}년 ${month}월`;
  }
const sendEmpList = async () => {
if (loading.value) return;
if(selectEmp.value.length === 0){
  alert('불러올 사원정보를 선택해주세요.')
  return;
}
const isConfirmed = window.confirm(`${formatYm(store.state.AdminYYMM)}의 기존 정보와 중복되는 정보는
자동으로 덮어쓰기 처리됩니다. 
${formatYm(date.value)}의 사원정보를 불러오시겠습니까?
`);
if (isConfirmed) {
loading.value = true;
const insertDate = store.state.AdminYYMM;
const selectDate = date.value;
try {
  const response = await axios.post(`/api/sendEmpList/${insertDate}/${selectDate}/${code.value}`, { form: selectEmp.value });
  const result = response.data;
  if (result.success === true) {
    emit('success');
    dialog.value = false;
  } else {
    alert('사원 불러오기에 실패하였습니다. 관리자에게 문의해주세요.');
  }
} catch (error) {
  alert('사원 불러오기를 처리할 수 없습니다.');
  console.error(error);
} finally {
  loading.value = false;
}
}else{
return;
}
};
const loadEmpList = async () => {
if (loading.value) return;
loading.value = true;
try {
  const response = await axios.get(`/api/loadEmpList/${code.value}/${date.value}`);
  const responseData = response.data;
  if (responseData) {
    empList.value = responseData;
    return responseData;
  } else {
    console.error('에러가 발생했습니다.');
  }
} catch (error) {
  console.error('errorMsg:', error);
} finally {
  loading.value = false;
}
};
</script>

<style scoped>
.monthLabelBtn {
background-color: #fff;
color: #333;
border: 1px solid rgb(203, 203, 203);
font-size: 16px;
min-width: 50px !important;
max-height: 40px !important;
}
.btnBg {
background: linear-gradient(45deg, #e89e7e, #ff9b70); 
color: #fff !important;
}
</style>