<template>
  <v-dialog persistent v-model="dialog" max-width="430" >
    <template v-slot:activator="{ props: activatorProps }">
      <v-icon color="blue-darken-3" v-bind="activatorProps" class="SsDeleteIcon">{{ mdiPen }}</v-icon>
    </template>
    <v-card class="fontNotoSans400">
      <v-card-title class="inoutCtrlTitle pr-6 pl-6 pt-4">
        <span>사원 기초정보 수정</span>
        <v-icon class="closeIcon mt-1" @click="dialog = false" size="small">{{ mdiWindowClose }}</v-icon>
      </v-card-title>
<v-table class="wageBasicUpdateTb ml-4 mr-4">
          <tbody>         
            <tr>
              <td>사번</td>
              <td>
                {{form.emp_num}}
              </td>
            </tr>
            <tr>
              <td>이름</td>
              <td>
                {{form.emp_name}}
              </td>
            </tr>
            <tr>
              <td>직위</td>
              <td>
                {{form.emp_job}}
              </td>
            </tr>
             <tr v-if="form.ec_pay_type === '정산'">
              <td>시급</td>
              <td>
                <input
                  type="text"
                  v-model="form.payMonth"
                  hide-details="auto"
                  maxlength="20" 
                  color="indigo"
                  variant="plain" 
                />
              </td>
            </tr>
            <tr v-if="form.ec_pay_type === '약정'">
              <td>월급</td>
              <td>
                <input
                  type="text"
                  v-model="form.payMonth"
                  hide-details="auto"
                  maxlength="20" 
                  color="indigo"
                  variant="plain" 
                />
              </td>
            </tr>
             <tr>
              <td>보험</td>
               <td>
                <input type="checkbox" v-model="form.emp_insu" :value=1> 국민연금
                <input type="checkbox" v-model="form.emp_insu" :value=2> 건강보험
                <input type="checkbox" v-model="form.emp_insu" :value=3> 고용보험 
                <input type="checkbox" v-model="form.emp_insu" :value=4> 산재보험 
              </td>
            </tr>
            </tbody>
            </v-table>
      
      <v-btn @click="setWageBasic" color="#464646" style="font-size: 15px;">저장</v-btn>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, reactive, defineProps, watch } from 'vue';
import { mdiWindowClose } from '@mdi/js';
import axios from "axios";
import {
  mdiPen,
} from '@mdi/js';
// eslint-disable-next-line no-undef
const emit = defineEmits(['success']);

const props = defineProps({
  item: {
    type: Object,
    required: true,
  }
});
const form = reactive({
  sisul_code : props.item.sisul_code,
  emp_num : props.item.emp_num,
  emp_name : props.item.emp_name,
  emp_job : props.item.emp_job,
  ea_ym : props.item.ea_ym,
  payMonth : props.item.payMonth,
  emp_insu : JSON.parse(props.item.emp_insu),
  ec_pay_type : props.item.ec_pay_type
})
watch(() => props.item, (newValue) => {
  if (newValue) {
    form.sisul_code = newValue.sisul_code;
    form.emp_num = newValue.emp_num;
    form.emp_name = newValue.emp_name;
    form.emp_job = newValue.emp_job;
    form.ea_ym = newValue.ea_ym;
    form.payMonth = newValue.payMonth;
    form.emp_insu = JSON.parse(newValue.emp_insu);
    form.ec_pay_type = newValue.ec_pay_type;
  }
}, { deep: true }); // 깊은 감지를 원한다면 deep 옵션을 추가


const dialog = ref(false);
watch(dialog, (newVal) => {
  if (newVal) {
    resetForm();
  }
});

function resetForm() {
  form.sisul_code = props.item.sisul_code;
  form.emp_num = props.item.emp_num;
  form.emp_name = props.item.emp_name;
  form.emp_job = props.item.emp_job;
  form.ea_ym = props.item.ea_ym;
  form.payMonth = props.item.payMonth;
  form.emp_insu = JSON.parse(props.item.emp_insu);
  form.ec_pay_type = props.item.ec_pay_type;
}

const setWageBasic = async () => {
  try {
    console.log(form,'form')
    const response = await axios.post(`/api/setWageBasic/`, { form: form });
    const result = response.data;
    if (result.success === true) {
      await emit('success');
      alert('정상 처리되었습니다.');
    } else {
      alert('실패하였습니다. 관리자에게 문의해주세요.');
    }
  } catch (error) {
    alert('처리할 수 없습니다.');
    console.error(error);
  }
};

</script>
<style scoped>
  .inoutCtrlTitle{
  align-items: center;
  justify-content: center;
    font-size: 17px;
    border-bottom: 2px solid #969696;
  }
  .closeIcon{
  float: right; 
  color: black;
  opacity: 0.4;
  cursor:pointer;
  align-items: center;
  justify-content: center;
  display: flex;
}
.closeIcon:hover{
  color: rgb(20, 0, 197);
  opacity: 1;
}
.wageBasicUpdateTb{
  font-size: 14px;

}
.wageBasicUpdateTb input{
  font-size: 14px;
  padding: 3px 0px;
}
.wageBasicUpdateTb td{
  min-width: 60px;
}
</style>