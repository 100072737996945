import { createApp } from 'vue'
import App from './App.vue'
import { vuetify } from './plugins'
import router from './router'
import store from './store';
import CKEditor from '@ckeditor/ckeditor5-vue';

import './assets/styles/main.scss'; // 스타일 파일을 import
import './assets/styles/XS.scss'; // 480px 이하
// import './assets/styles/S.scss'; // 스타일 파일을 import
import './assets/styles/M.scss'; // 스타일 파일을 import
import './assets/styles/L.scss'; // 스타일 파일을 import
// import './assets/styles/XL.scss'; // 스타일 파일을 import

import './assets/admin_styles/main.scss'; // 스타일 파일을 import
import './assets/Ss_styles/main.scss'; // 스타일 파일을 import
import './assets/admin/main.scss'; // 스타일 파일을 import

createApp(App)  
.use(router)
.use(store) 
.use(vuetify)
.use(CKEditor)
.mount('#app')
