<template>
  <v-icon @click="dialogOpen()">{{ mdiPen }}</v-icon>

  <v-dialog v-model="dialog" style="width: 900px" class="fontNotoSans400" persistent>
    <div v-if="loading" class="spinner tac"></div>

    <div class="wageUpdateModalCard" v-else>
    <div class="tar">
      <v-icon style="width: 35px; height: 35px;" color="#333" @click="dialogClose">{{mdiClose}}</v-icon>
    </div>
      <h2 class="tal">수동조정여부</h2>
    <div class="wageUpdateGubunDiv pl-4">
      <v-switch
        v-model="manual"
        color="primary"
        label="수동조정을 고정하고싶다면 체크해주세요."
        hide-details
        inset
      ></v-switch>
    </div>
      <h2 class="tal">사원정보</h2>
    <div class="wageUpdateGubunDivReadOnly">
      <v-text-field readonly v-model="form.sisulName" label="시설명" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field readonly v-model="form.empName" label="사원이름" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field readonly v-model="form.empJob" label="사원직종" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field readonly v-model="form.ym" label="임금연월" variant="outlined" hide-details type="text" flat density="compact"/>
    </div> 
      <h2 class="tal">임금기초</h2>
    <div class="wageUpdateGubunDiv">
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'timePay')" @blur="applyFormat(item, 'timePay')" v-model="form.timePay" label="시급" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'totalDays')" @blur="applyFormat(item, 'totalDays')" v-model="form.totalDays" label="근무일" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'totalHours')" @blur="applyFormat(item, 'totalHours')" v-model="form.totalHours" label="근무시간" variant="outlined" hide-details type="text" flat density="compact"/>
    </div>
      <h2 class="tal">과세임금</h2>
    <div class="wageUpdateGubunDiv">
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'base')" @blur="applyFormat(item, 'base')"  v-model="form.base" label="기본급" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'dayOffPay')" @blur="applyFormat(item, 'dayOffPay')"  v-model="form.dayOffPay" label="연차수당" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'longTerm')" @blur="applyFormat(item, 'longTerm')"  v-model="form.longTerm" label="장기근속" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'bonus')" @blur="applyFormat(item, 'bonus')"  v-model="form.bonus" label="상여금" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'eduPay')" @blur="applyFormat(item, 'eduPay')"  v-model="form.eduPay" label="교육비" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'bojon')" @blur="applyFormat(item, 'bojon')"  v-model="form.bojon" label="보존수당" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'etc')" @blur="applyFormat(item, 'etc')"  v-model="form.etc" label="기타수당" variant="outlined" hide-details type="text" flat density="compact"/>
    </div>
      <h2 class="tal">연·야·휴</h2>
    <div class="wageUpdateGubunDiv">
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'nightHour')" @blur="applyFormat(item, 'nightHour')" v-model="form.nightHour" label="야간근무시간" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'night')" @blur="applyFormat(item, 'night')"  v-model="form.night" label="야간근무수당" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'overtimeHour')" @blur="applyFormat(item, 'overtimeHour')" v-model="form.overtimeHour" label="연장근무시간" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'overtime')" @blur="applyFormat(item, 'overtime')"  v-model="form.overtime" label="연장근무수당" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'holidayHour')" @blur="applyFormat(item, 'holidayHour')" v-model="form.holidayHour" label="휴일근무시간" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'holiday')" @blur="applyFormat(item, 'holiday')"  v-model="form.holiday" label="휴일근무수당" variant="outlined" hide-details type="text" flat density="compact"/>
    </div>
      <h2 class="tal">비과세임금</h2>
    <div class="wageUpdateGubunDiv">
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'onhNonTax')" @blur="applyFormat(item, 'onhNonTax')"  v-model="form.onhNonTax" label="연야휴 비과세 처리액" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'lunch')" @blur="applyFormat(item, 'lunch')"  v-model="form.lunch" label="중식대" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'car')" @blur="applyFormat(item, 'car')"  v-model="form.car" label="차량비" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'childcare')" @blur="applyFormat(item, 'childcare')"  v-model="form.childcare" label="양육비" variant="outlined" hide-details type="text" flat density="compact"/>
    </div>
    <h2 class="tal">합산임금관련</h2>
    <div class="wageUpdateGubunDiv">
      <v-text-field readonly bg-color="#f1f1f1" color="blue-accent-4"  v-model="form.total" label="임금합계" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'taxable')" @blur="applyFormat(item, 'taxable')"  v-model="form.taxable" label="과세임금" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'usual')" @blur="applyFormat(item, 'usual')"  v-model="form.usual" label="통상임금" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'minimum')" @blur="applyFormat(item, 'minimum')"  v-model="form.minimum" label="최저임금" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'realMoney')" @blur="applyFormat(item, 'realMoney')"  v-model="form.realMoney" label="실수령액" variant="outlined" hide-details type="text" flat density="compact"/>
    </div>
    <v-btn class="btnColorDark" @click="insuCalc()">공제자동계산</v-btn>

    <h2 class="tal">근로자 공제</h2>
    <div class="wageUpdateGubunDiv">
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_yeon')" @blur="applyFormat(item, 'l_yeon')"  v-model="form.l_yeon" label="국민연금" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_gun')" @blur="applyFormat(item, 'l_gun')"  v-model="form.l_gun" label="건강보험" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_jang')" @blur="applyFormat(item, 'l_jang')"  v-model="form.l_jang" label="장기요양" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_go')" @blur="applyFormat(item, 'l_go')"  v-model="form.l_go" label="고용보험" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_so')" @blur="applyFormat(item, 'l_so')"  v-model="form.l_so" label="소득세" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_ji')" @blur="applyFormat(item, 'l_ji')"  v-model="form.l_ji" label="지방세" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_jiwon')" @blur="applyFormat(item, 'l_jiwon')"  v-model="form.l_jiwon" label="지원공제" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_sik')" @blur="applyFormat(item, 'l_sik')"  v-model="form.l_sik" label="식비공제" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_etc')" @blur="applyFormat(item, 'l_etc')"  v-model="form.l_etc" label="기타공제" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_yearEnd')" @blur="applyFormat(item, 'l_yearEnd')"  v-model="form.l_yearEnd" label="연말정산" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'l_total')" @blur="applyFormat(item, 'l_total')"  v-model="form.l_total" label="공제합계" variant="outlined" hide-details type="text" flat density="compact"/>
    </div>
    <h2 class="tal">기관 공제</h2>
    <div class="wageUpdateGubunDiv">
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'c_yeon')" @blur="applyFormat(item, 'c_yeon')"  v-model="form.c_yeon" label="국민연금" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'c_gun')" @blur="applyFormat(item, 'c_gun')"  v-model="form.c_gun" label="건강보험" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'c_jang')" @blur="applyFormat(item, 'c_jang')"  v-model="form.c_jang" label="장기요양" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'c_go')" @blur="applyFormat(item, 'c_go')"  v-model="form.c_go" label="고용보험" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'c_sil')" @blur="applyFormat(item, 'c_sil')"  v-model="form.c_sil" label="실업급여" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'c_san')" @blur="applyFormat(item, 'c_san')"  v-model="form.c_san" label="산재보험" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'c_out')" @blur="applyFormat(item, 'c_out')"  v-model="form.c_out" label="퇴직금" variant="outlined" hide-details type="text" flat density="compact"/>
      <v-text-field color="blue-accent-4" @focus="clearFormat(item, 'c_total')" @blur="applyFormat(item, 'c_total')"  v-model="form.c_total" label="공제합계" variant="outlined" hide-details type="text" flat density="compact"/>
    </div>

      <v-btn @click="updateOneWageInfo()" class="clientInfoBtn">저장</v-btn>
      <v-btn @click="dialogClose()" class="cancelRegistBtn">취소</v-btn>
    </div>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, defineEmits, defineProps, watch } from "vue";
import { mdiPen , mdiClose} from "@mdi/js";
import axios from "axios";
const loading = ref(false);
const dialog = ref(false);
const form = ref([] as any);
const manual = ref(false);
const tax = ref([] as any);
const soji = ref([] as any);

// eslint-disable-next-line no-undef
const emit = defineEmits(["success"]);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  tax: {
    type: Object,
    required: true,
  },
  soji: {
    type: Object,
    required: true,
  },
});

const dialogOpen = async () => {
  form.value = (props.item);
  tax.value = props.tax;
  soji.value = props.soji;
  if(form.value.ew_manual === '1'){
    manual.value = true;
  }else {
    manual.value = false;
  }
  dialog.value = true;
};
const dialogClose = async () => {
  dialog.value = false;
};
watch([
  () => form.value.nightHour, 
  () => form.value.overtimeHour, 
  () => form.value.holidayHour, 
  () => form.value.timePay,
  () => form.value.lunch,
  () => form.value.car,
  () => form.value.childcare,
  () => form.value.dayOffPay,
  () => form.value.bonus,
  () => form.value.eduPay,
  () => form.value.longTerm,
  () => form.value.etc,
  () => form.value.bojon,
], ([
  nightHour, 
  overtimeHour,
  holidayHour,
  timePay,
]) => {

    form.value.night = ac(nc(nightHour) * nc(timePay) / 2) || 0;
    form.value.overtime = ac(nc(overtimeHour) * nc(timePay) / 2) || 0;
    form.value.holiday = ac(nc(holidayHour) * nc(timePay) / 2) || 0;


    let onh = nc(form.value.night) + nc(form.value.overtime) + nc(form.value.holiday);

    if(onh >= 200000){
      form.value.onhNonTax = '200,000';
    }else{
      form.value.onhNonTax = ac(onh);
    }

    form.value.base = 
  ac(
    nc(form.value.total) - 
    (nc(form.value.overtime) + 
    nc(form.value.night) + 
    nc(form.value.holiday) + 
    nc(form.value.longTerm) + 
    nc(form.value.etc) + 
    nc(form.value.lunch) + 
    nc(form.value.dayOffPay) + 
    nc(form.value.bonus) + 
    nc(form.value.eduPay)  + 
    nc(form.value.car) + 
    nc(form.value.childcare) + 
    nc(form.value.bojon))); 

  form.value.usual = ac(nc(form.value.base) + nc(form.value.longTerm) + nc(form.value.etc) + nc(form.value.car) + nc(form.value.childcare) + nc(form.value.bojon));
  form.value.taxable = ac(nc(form.value.total) - (nc(form.value.onhNonTax) + nc(form.value.lunch) + nc(form.value.car) + nc(form.value.childcare)));
  form.value.minimum = ac(nc(form.value.base) + nc(form.value.longTerm) + nc(form.value.etc) + nc(form.value.lunch) + nc(form.value.car) + nc(form.value.childcare) + nc(form.value.bojon));
  form.value.realMoney = ac(nc(form.value.total) - nc(form.value.l_total));
});
watch([
  () => form.value.l_yeon, 
  () => form.value.l_gun, 
  () => form.value.l_jang, 
  () => form.value.l_go,
  () => form.value.l_so,
  () => form.value.l_ji,
  () => form.value.l_jiwon,
  () => form.value.l_sik,
  () => form.value.l_etc,
  () => form.value.l_yearEnd,
  () => form.value.longTerm,
  () => form.value.c_yeon,
  () => form.value.c_gun,
  () => form.value.c_jang,
  () => form.value.c_go,
  () => form.value.c_sil,
  () => form.value.c_san,
  () => form.value.c_out,

], ([
  l_yeon, 
  l_gun, 
  l_jang, 
  l_go,
  l_so,
  l_ji,
  l_jiwon,
  l_sik,
  l_etc,
  l_yearEnd,
  longTerm,
  c_yeon,
  c_gun,
  c_jang,
  c_go,
  c_sil,
  c_san,
  c_out,
]) => {
    form.value.l_yeon = l_yeon || 0;
    form.value.l_gun = l_gun || 0;
    form.value.l_jang = l_jang || 0;
    form.value.l_go = l_go || 0;
    form.value.l_so = l_so || 0;
    form.value.l_ji = l_ji || 0;
    form.value.l_jiwon = l_jiwon || 0;
    form.value.l_sik = l_sik || 0;
    form.value.l_etc = l_etc || 0;
    form.value.l_yearEnd = l_yearEnd || 0;
    form.value.longTerm = longTerm || 0;
    form.value.c_yeon = c_yeon || 0;
    form.value.c_gun = c_gun || 0;
    form.value.c_jang = c_jang || 0;
    form.value.c_go = c_go || 0;
    form.value.c_sil = c_sil || 0;
    form.value.c_san = c_san || 0;
    form.value.c_out = c_out || 0;


  form.value.l_total = ac(nc(form.value.l_yeon) + nc(form.value.l_gun) + nc(form.value.l_jang) + nc(form.value.l_go) + nc(form.value.l_so) + nc(form.value.l_ji) + nc(form.value.l_jiwon)+ nc(form.value.l_sik) + nc(form.value.l_etc) + nc(form.value.l_yearEnd));
  form.value.c_total = ac(nc(form.value.c_yeon) + nc(form.value.c_gun) + nc(form.value.c_jang) + nc(form.value.c_go) + nc(form.value.c_sil) + nc(form.value.c_san) + nc(form.value.c_out));
  form.value.realMoney = ac(nc(form.value.total) - nc(form.value.l_total));

});
const insuCalc = () => {
  let t = nc(form.value.taxable);
  let v = tax.value;
  if(form.value.l_yeon !== '0' ){
    form.value.l_yeon = ac(t * v.tr_yeon);
  }
  if(form.value.l_gun !== '0'){
    form.value.l_gun = ac(t * v.tr_gun);
  }
  if(form.value.l_jang !== '0'){
    form.value.l_jang = ac(t * v.tr_gun * v.tr_jang);
  }
  if(form.value.l_go !== '0'){
    form.value.l_go = ac(t * v.tr_go);
  }
  if(form.value.c_yeon !== '0'){
    form.value.c_yeon = ac(t * v.tr_yeon);
  }
  if(form.value.c_gun !== '0'){
    form.value.c_gun = ac(t * v.tr_gun);
  }
  if(form.value.c_jang !== '0'){
    form.value.c_jang = ac(t * v.tr_gun * v.tr_jang);
  }
  if(form.value.c_go !== '0'){
    form.value.c_go = ac(t * v.tr_go);
  }

  form.value.l_so = ac(getDepe1(t / 1000));
  form.value.l_ji = ac((getDepe1(t / 1000))/10);
  form.value.c_sil = ac(t * v.tr_sil);
  form.value.c_san = ac(t * v.tr_san);
  form.value.c_out = ac(nc(form.value.usual)/12);
  form.value.realMoney = ac(nc(form.value.total) - nc(form.value.l_total));

}
function getDepe1(value: any) {
  for (let obj of soji.value) {
    if (value >= obj.income1 && value < obj.income2) {
      return obj.depe_1;
    }
  }
  return null;
}
const updateOneWageInfo = async () => {
  try {
    if(manual.value === true){
      form.value.ew_manual = '1'
    }else {
      form.value.ew_manual = '0'
    }
    const response = await axios.post("/api/updateOneWageInfo", { form: form.value });
    const result = response.data;
    if (result.success === true) {
      alert("수동조정이 완료되었습니다.");
      dialog.value = false;
      emit("success");
    } else {
      alert("수동조정에 실패하였습니다. 관리자에게 문의해주세요.");
    }
  } catch (error) {
    alert("처리할 수 없습니다.");
    console.error(error);
  }
};
function nc(value:any) {
  return parseFloat(value.toString().replace(/,/g, '')) || 0;
}
function ac(value: any) {
  //1단위 절삭 후 컴마
  let num = Number(value);
  if (isNaN(num)) return 0;
  num = Math.floor(num / 10) * 10;
  return num.toLocaleString()  || 0;
}
function clearFormat(item: any, key: any) {
  // 인풋의 값을 컴마 없이 만듭니다.
  item[key] = unformatNumber(item[key]);

  // 값이 0이면 빈 값으로 설정하여 placeholder를 보이도록 합니다.
  if (item[key] === '0' || item[key] === '0.0' || item[key] === null || item[key] === '' || item[key] === undefined || item[key] === 0 || item[key] === '-0') {
    item[key] = '';
  }
}

function applyFormat(item: any, key: any) {
  // 값이 비어있으면 0으로 설정
  if (item[key] === '' || item[key] === null || item[key] === undefined || item[key] === '-0') {
    item[key] = 0;
  } else {
    // 포커스가 해제되면 천 단위로 컴마를 추가
    item[key] = formatNumber(item[key]);
  }
}

function formatNumber(value: any): string {
  // 숫자인지 확인하고 천 단위로 컴마 추가
  const number = parseFloat(value);
  if (!isNaN(number)) {
    return number.toLocaleString();
  }
  return value;
}

function unformatNumber(value: any) {
  // 컴마를 제거
  return value.toString().replace(/,/g, '');
}
</script>
<style>
.wageUpdateModalCard{
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  margin: auto;
  padding: 40px 25px;
  border-radius: 10px;
}
.wageUpdateModalCard .v-text-field {
  margin: 15px 1%;
  display: inline-block;
  width: 23%;
}
.wageUpdateGubunDiv input {
  text-align: right;
}
.wageUpdateModalCard h2 {
  margin-left: 12px;
}
.wageUpdateGubunDiv,
.wageUpdateGubunDivReadOnly{
  margin:10px 0 40px 0;
}
.wageUpdateGubunDivReadOnly .v-text-field{
  background-color: #f1f1f1;
}
.wageUpdateGubunDivReadOnly input {
  text-align: center;
}
</style>