<template>
  <v-btn @click="dialogOpen()" class="btnColorBlue">사원 업로드</v-btn>
  <v-dialog v-model="dialog"  width="550px" class="fontNotoSans400">
    <v-card>
      <v-tabs v-model="tab" align-tabs="center" color="primary">
        <v-tab value="one">현원</v-tab>
        <v-tab value="two">퇴사원</v-tab>
      </v-tabs>
      <v-card-text style="padding-top: 0;">
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="one">
            <div v-if="loading" class="spinner tac"></div>
            <v-data-table no-data-text="데이터가 존재하지 않습니다." v-else fixed-header density="compact" scrollable :headers="headersArr" :items="empList"
              item-value="name" class="SsTb fontNotoSans400" style="
                height: calc(100vh - 200px); 
                max-height: 600px;
                overflow-y: hidden; 
                position: relative;
              " :items-per-page="-1" :items-per-page-options="[
                { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
              ]">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="fontNotoSans700" style="max-width: 120px;">
                    <v-btn v-if="(selectEmp.length !== empList.length)" @click="allSelectEmp" class="btnColorWhite"
                      style="width: 100px;">전체체크</v-btn>
                    <v-btn v-else-if="(selectEmp.length === empList.length) && empList.length" @click="allSelectEmp"
                      class="btnColorWhite" style="width: 100px;">전체체크해제</v-btn>
                  </v-toolbar-title>
                  <div v-if="empList.length > 0">
                    <p>{{ formatYm(store.state.AdminYYMM) }} 임금기초 리스트에</p>
                    <p>업로드되지 않은 사원입니다.</p>
                  </div>
                  <div v-else>
                    <p>{{ formatYm(store.state.AdminYYMM) }} 임금기초 리스트에</p>
                    <p>모든 사원이 업로드된 상태입니다.</p>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item, index }">
                <tr class="SsTbTr">
                  <td class="tal" style="display: flex; justify-content: left; align-items: center;">
                    <v-checkbox style="display: inline-block;" v-model="selectEmp" :value="item" color="secondary"
                      hide-details></v-checkbox>
                    <span>{{ index + 1 }}.</span>
                  </td>
                  <td class="tac">{{ item.emp_num }}</td>
                  <td class="tac">{{ item.emp_name }}</td>
                  <td class="tac">{{ item.emp_job }}</td>
                </tr>
              </template>
            </v-data-table>
            <v-btn style="width: 100%;" class="btnColorBlue mb-1" @click="sendNowEmpList()">사원 업로드</v-btn>
            <v-btn style="width: 100%;" class="btnColorDark" @click="notSelectEmp()">취소</v-btn>
          </v-tabs-window-item>

          <v-tabs-window-item value="two">
            <div v-if="loading" class="spinner tac"></div>
            <v-data-table no-data-text="데이터가 존재하지 않습니다."  v-else fixed-header density="compact" scrollable :headers="headersArrOut" :items="empOutList"
              item-value="name" class="SsTb fontNotoSans400" style="
                height: calc(100vh - 200px); 
                max-height: 600px;
                overflow-y: hidden; 
                position: relative;
                " :items-per-page="-1" :items-per-page-options="[
                  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
                ]">
                <template v-slot:top>
                  <v-toolbar flat style="padding: 0 20px;">
                    <div class="fontNotoSans700" style="max-width: 100%;">
                      <p>필요 시 퇴사원 정보를 임금기초에 추가할 수 있습니다.</p>
                      <p>[전월내역불러오기] 기능에선 퇴사자를 불러올 수 없습니다.</p>
                    </div>
                  </v-toolbar>
                </template>
              <template v-slot:item="{ item, index }">
                <tr class="SsTbTr">
                  <td class="tal" style="display: flex; justify-content: left; align-items: center;">
                    <v-checkbox style="display: inline-block;" v-model="selectOutEmp" :value="item" color="secondary"
                      hide-details></v-checkbox>
                    <span>{{ index + 1 }}.</span>
                  </td>
                  <td class="tac">{{ item.emp_num }}</td>
                  <td class="tac">{{ item.emp_name }}</td>
                  <td class="tac">{{ item.emp_job }}</td>
                  <td class="tac">{{ item.emp_out_date }}</td>
                </tr>
              </template>
            </v-data-table>
            <v-btn style="width: 100%;" class="btnColorBlue mb-1" @click="sendOutEmpList()">퇴사원 업로드</v-btn>
            <v-btn style="width: 100%;" class="btnColorDark" @click="notSelectEmp()">취소</v-btn>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>
<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { ref } from 'vue';
const tab = ref(false);
const dialog = ref(false);
const code = ref(store.state.AdminSisulCode) as any;

// eslint-disable-next-line no-undef
const emit = defineEmits(['success'])
const loading = ref(false);
const empList = ref([] as any[]);
const empOutList = ref([] as any[]);

const selectEmp = ref<string[]>([]);
const selectOutEmp = ref<string[]>([]);
const formatYm = (value: any) => {
  if (!value) return '';
  const [year, month] = value.split('-');
  return `${year}년 ${month}월`;
}
const dialogOpen = async () => {
  dialog.value = true;
  selectEmp.value = [];
  selectOutEmp.value = [];
  await loadNowEmpList();
  await loadOutEmpList();
}
const headersArr = ref([
  { title: 'No.', key: 'index', align: 'center', sortable: false },
  { title: '사원번호', key: 'emp_num', align: 'center', sortable: true },
  { title: '이름', key: 'emp_name', align: 'center', sortable: true },
  { title: '직위', key: 'emp_job', align: 'center', sortable: true },
] as any[]);
const headersArrOut = ref([
  { title: 'No.', key: 'index', align: 'center', sortable: false },
  { title: '사원번호', key: 'emp_num', align: 'center', sortable: true },
  { title: '이름', key: 'emp_name', align: 'center', sortable: true },
  { title: '직위', key: 'emp_job', align: 'center', sortable: true },
  { title: '퇴사일', key: 'emp_out_date', align: 'center', sortable: true },
] as any[]);
const allSelectEmp = () => {
  if (selectEmp.value.length === empList.value.length) {
    selectEmp.value = [];
  } else {
    selectEmp.value = empList.value;
  }
};
const notSelectEmp = () => {
  dialog.value = false;
};

const sendNowEmpList = async () => {
  if (loading.value) return;
  if (selectEmp.value.length === 0) {
    alert('불러올 사원정보를 선택해주세요.')
    return;
  }
  const isConfirmed = window.confirm('사원을 업로드하시겠습니까?');
  if (isConfirmed) {
    loading.value = true;
    const insertDate = store.state.AdminYYMM;
    try {
      const response = await axios.post(`/api/sendNowEmpList/${insertDate}/${code.value}`, { form: selectEmp.value });
      const result = response.data;
      if (result.success === true) {
        emit('success');
        dialog.value = false;
      } else {
        alert('사원 업로드에 실패하였습니다. 관리자에게 문의해주세요.');
      }
    } catch (error) {
      alert('사원 업로드를 처리할 수 없습니다.');
      console.error(error);
    } finally {
      loading.value = false;
    }
  } else {
    return;
  }
};

const sendOutEmpList = async () => {
  if (loading.value) return;
  if (selectOutEmp.value.length === 0) {
    alert('불러올 퇴사원정보를 선택해주세요.')
    return;
  }
  const isConfirmed = window.confirm('퇴사한 사원을 업로드하시겠습니까?');
  if (isConfirmed) {
    loading.value = true;
    const insertDate = store.state.AdminYYMM;
    try {
      const response = await axios.post(`/api/sendOutEmpList/${insertDate}/${code.value}`, { form: selectOutEmp.value });
      const result = response.data;
      if (result.success === true) {
        emit('success');
        dialog.value = false;
      } else {
        alert('퇴사한 사원의 업로드에 실패하였습니다. 관리자에게 문의해주세요.');
      }
    } catch (error) {
      alert('퇴사한 사원의 업로드를 처리할 수 없습니다.');
      console.error(error);
    } finally {
      loading.value = false;
    }
  } else {
    return;
  }
};
const loadNowEmpList = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const response = await axios.get(`/api/loadNowEmpList/${code.value}/${store.state.AdminYYMM}`);
    const responseData = response.data;
    if (responseData) {
      empList.value = responseData;
      console.log(empList.value, 'empList.value')
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};
const loadOutEmpList = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const response = await axios.get(`/api/loadOutEmpList/${code.value}/${store.state.AdminYYMM}`);
    const responseData = response.data;
    if (responseData) {
      empOutList.value = responseData;
      console.log(empOutList.value, 'empOutList.value')
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.monthLabelBtn {
  background-color: #fff;
  color: #333;
  border: 1px solid rgb(203, 203, 203);
  font-size: 16px;
  min-width: 50px !important;
  max-height: 40px !important;
}

.btnBg {
  background: linear-gradient(45deg, #e89e7e, #ff9b70);
  color: #fff !important;
}

.v-tabs--density-default {
  --v-tabs-height: 40px !important;
  margin: 12px 0;
}

.v-tab {
  font-size: 16px !important;
}
</style>