<template>
  <template v-if="loading">
    <AdminLoadingAction />
  </template>
    <v-data-table 
      no-data-text="데이터가 존재하지 않습니다."  
      fixed-header 
      density="compact" 
      scrollable 
      :headers="headersArr" 
      :items="filteredItems"
      :search="search" 
      item-value="name" 
      class="adminDataTb twoHeadTb" 
      :items-per-page="-1" 
      :items-per-page-options="[
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="fontNotoSans700" style="max-width: 160px;">🧑‍💼 임금기초</v-toolbar-title>
          <h3><FormatMonth /></h3>
          <v-toolbar-title class="fontNotoSans700">
          </v-toolbar-title>

          <WageBasicLoadNowEmp @success="readEmpAtt()" />
          <v-divider class="mx-4" inset vertical></v-divider>
          <WageBasicLoadEmp @success="readEmpAtt()" />
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-btn class="btnColorDark" v-bind="props"> 저장/삭제 ▾ </v-btn>
            </template>
            <v-list>
              <v-list-item>
          <v-btn @click="updateAllEmpAtt()" class="btnColorUpdate" :append-icon="mdiContentSave">일괄 저장</v-btn>
              </v-list-item>
              <v-list-item>
          <v-btn @click="deleteAllEmpAtt()" class="btnColorDark" :append-icon="mdiDelete">일괄 삭제</v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn v-if="endEmpStatus" class="btnColorPurple" @click="endEmpAtt"> 마감 </v-btn>
            <v-btn v-else class="btnColorPurple" @click="cancelEndEmpAtt"> 마감해제 </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field class="inoutSearchInput" style="
          background-color: #fff;
          max-width:200px; 
          width:auto; 
          min-width:70px;
          margin: 0 20px 0 0;
        " v-model="search" color="#0f7545" label="이름/직위 검색" single-line clearable :append-inner-icon="mdiMagnify" flat
            hide-details="auto" variant="outlined" density="compact">
          </v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, index }">
        <tr class="SsAttTr">
          <td class="tac">{{ index + 1 }}</td>
          <td class="tac">{{ item.emp_num }}</td>
          <td class="tac">{{ item.emp_name }}<span v-if="item.emp_status === 0">({{item.emp_out_date}}퇴사)</span></td>
          <td class="tac">{{ item.emp_job }}</td>
          <td class="tar">{{ totalPay(item) }}<WageBasicEmpUpdate :item="item" @success="readEmpAtt()"/></td>
          <template v-if="!item.isEditing">
            <td class="tac">{{ item.ea_work_day }}</td>
            <td class="tac">{{ item.ea_day_off }}</td>
            <td class="tac">{{ item.ea_work_time }}</td>
            <td class="tac">{{ item.ea_over_time }}</td>
            <td class="tac">{{ item.ea_night_time }}</td>
            <td class="tac">{{ item.ea_holiday_time }}</td>
            <td class="tac">{{ item.ea_longterm_pay }}</td>
            <td class="tac">{{ item.ea_bonus }}</td>
            <td class="tac">{{ item.ea_edu_pay }}</td>
            <td class="tac">{{ item.ea_day_off_pay }}</td>
            <td class="tac">{{ item.ea_etc_pay }}</td>
            <td class="tac">{{ item.ea_lunch_pay }}</td>
            <td class="tac">{{ item.ea_car_pay }}</td>
            <td class="tac">{{ item.ea_childcare_pay }}</td>
            <td class="tac">{{ item.ea_meal_deduct }}</td>
            <td class="tac">{{ item.ea_etc_deduct }}</td>
            <td class="tac">{{ item.ea_year_end }}</td>
          </template>
          <template v-else>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_work_day"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_work_day')"
              @blur="applyFormat(item, 'ea_work_day')"
              maxlength="4"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_day_off"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_day_off')"
              @blur="applyFormat(item, 'ea_day_off')"
              maxlength="4"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_work_time"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_work_time')"
              @blur="applyFormat(item, 'ea_work_time')"
              maxlength="4"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_over_time"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_over_time')"
              @blur="applyFormat(item, 'ea_over_time')"
              maxlength="4"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_night_time"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_night_time')"
              @blur="applyFormat(item, 'ea_night_time')"
              maxlength="4"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_holiday_time"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_holiday_time')"
              @blur="applyFormat(item, 'ea_holiday_time')"
              maxlength="4"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_longterm_pay"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_longterm_pay')"
              @blur="applyFormat(item, 'ea_longterm_pay')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_bonus"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_bonus')"
              @blur="applyFormat(item, 'ea_bonus')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_edu_pay"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_edu_pay')"
              @blur="applyFormat(item, 'ea_edu_pay')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_day_off_pay"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_day_off_pay')"
              @blur="applyFormat(item, 'ea_day_off_pay')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_etc_pay"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_etc_pay')"
              @blur="applyFormat(item, 'ea_etc_pay')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_lunch_pay"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_lunch_pay')"
              @blur="applyFormat(item, 'ea_lunch_pay')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_car_pay"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_car_pay')"
              @blur="applyFormat(item, 'ea_car_pay')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_childcare_pay"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_childcare_pay')"
              @blur="applyFormat(item, 'ea_childcare_pay')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_meal_deduct"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_meal_deduct')"
              @blur="applyFormat(item, 'ea_meal_deduct')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_etc_deduct"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_etc_deduct')"
              @blur="applyFormat(item, 'ea_etc_deduct')"
              maxlength="10"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.ea_year_end"
              hide-details="auto"
              @focus="clearFormat(item, 'ea_year_end')"
              @blur="applyFormat(item, 'ea_year_end')"
              maxlength="10"
            />
          </td>
        </template>
          <td class="tac" v-if="endEmpStatus">
            <v-icon v-if="item.isEditing" @click="updateEmpAtt(item)" color="green-darken-3" class="SsDeleteIcon">{{ mdiContentSave }}</v-icon> 
            <v-icon v-if="item.isEditing" @click="outEdit(item)" color="blue-darken-3" class="SsDeleteIcon">{{ mdiPenRemove }}</v-icon>  
            <v-icon v-if="!item.isEditing" @click="onEdit(item)" color="blue-darken-3" class="SsDeleteIcon">{{ mdiPen }}</v-icon> 
            <WageBasicEmpDelete v-if="!item.isEditing" :item="item" @success="readEmpAtt()" />
          </td>
          <td class="tac" v-else>
           <p>마감완료</p>
          </td>
        </tr>
      </template>
    </v-data-table>
</template>
<script setup lang="ts">
import AdminLoadingAction from '@/components/admin/layout/AdminLoadingAction.vue'

import WageBasicEmpDelete from '@/components/admin/services/wage/basic/WageBasicEmpDelete.vue'
import WageBasicEmpUpdate from '@/components/admin/services/wage/basic/WageBasicEmpUpdate.vue'
import WageBasicLoadEmp from '@/components/admin/services/wage/basic/WageBasicLoadEmp.vue'
import WageBasicLoadNowEmp from '@/components/admin/services/wage/basic/WageBasicLoadNowEmp.vue'
import FormatMonth from '@/components/admin/common/datePicker/FormatMonth.vue'
import store from '@/store';
import axios from 'axios';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import {
  mdiMagnify,
  mdiContentSave,
  mdiPenRemove,
  mdiPen,
  mdiDelete
} from '@mdi/js';
const loading = ref(false);
const code = ref(store.state.AdminSisulCode) as any;
const date = ref(store.state.AdminYYMM);
const search = ref('');
const empAttList = ref([] as any[]);

const onEdit = (item: any) => {
  item.originalItem = { ...item }; 
  item.isEditing = true;
}
const outEdit = (item: any) => {
  Object.assign(item, item.originalItem); // 원래 값을 복원
  item.isEditing = false;
}
const totalPay= (item: any) => {
  let calc = 0;
  if(item.ec_pay_type === '정산'){
    calc =
    (nc(item.payMonth || 0) * nc(item.ea_work_time || 0)) + 
    (nc(item.payMonth || 0) /2 * (nc(item.ea_over_time || 0) + nc(item.ea_night_time || 0) + nc(item.ea_holiday_time || 0))) +
    (nc(item.ea_bonus || 0) + nc(item.ea_day_off_pay || 0) + nc(item.ea_edu_pay || 0) +
    nc(item.ea_etc_pay || 0) + nc(item.ea_longterm_pay || 0) + 
    nc(item.ea_lunch_pay) + nc(item.ea_car_pay) + nc(item.ea_childcare_pay))
    - (nc(item.ea_meal_deduct) + nc(item.ea_etc_deduct));
    return `${addComma(calc)} (정산)`;
  }else{
    return `${item.payMonth} (약정)`;
  }

};
function addComma(number: any) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return 0; 
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}

const headersArr = ref([
  { title: 'No.', key: 'index', align: 'center', sortable: false },
  {
    title: '기초정보', align: 'center', children: [
      { title: '사원번호', key: 'emp_num', align: 'center', sortable: true },
      { title: '이름', key: 'emp_name', align: 'center', sortable: true },
      { title: '직위', key: 'emp_job', align: 'center', sortable: true },
      { title: '월급여', key: 'payMonth', align: 'center', sortable: true },
    ]
  },
  {
    title: '근로일수', align: 'center', children: [
      { title: '기본', key: 'ea_work_day', align: 'center', sortable: true },
      { title: '연차', key: 'ea_day_off', align: 'center', sortable: true },
    ]
  },
  {
    title: '근로시간', align: 'center', children: [
      { title: '기본', key: 'ea_work_time', align: 'center', sortable: true },
      { title: '연장', key: 'ea_over_time', align: 'center', sortable: true },
      { title: '야간', key: 'ea_night_time', align: 'center', sortable: true },
      { title: '휴일', key: 'ea_holiday_time', align: 'center', sortable: true },
    ]
  },
  {
    title: '수당', align: 'center', children: [
      { title: '장기근속', key: 'ea_longterm_pay', align: 'center', sortable: true },
      { title: '상여금', key: 'ea_bonus', align: 'center', sortable: true },
      { title: '교육비', key: 'ea_edu_pay', align: 'center', sortable: true },
      { title: '연차수당', key: 'ea_day_off_pay', align: 'center', sortable: true },
      { title: '기타', key: 'ea_etc_pay', align: 'center', sortable: true },
      { title: '중식대', key: 'ea_lunch_pay', align: 'center', sortable: true },
      { title: '차량비', key: 'ea_car_pay', align: 'center', sortable: true },
      { title: '양육비', key: 'ea_childcare_pay', align: 'center', sortable: true },
    ]
  },
  {
    title: '공제', align: 'center', children: [
      { title: '식비', key: 'ea_meal_deduct', align: 'center', sortable: true },
      { title: '기타', key: 'ea_etc_deduct', align: 'center', sortable: true },
      { title: '연말정산', key: 'ea_year_end', align: 'center', sortable: true },
    ]
  },
  { title: '기능', key: '', align: 'center', sortable: false },
] as any[]);

const filteredItems = computed(() => {
  if (!search.value) {
    return empAttList.value;
  }
  return empAttList.value.filter(item => {
    const matchesSearch = (item.emp_name && item.emp_name.toLowerCase().includes(search.value.toLowerCase())) ||
      (item.emp_job && item.emp_job.toLowerCase().includes(search.value.toLowerCase()));
    return matchesSearch;
  });
});

function clearFormat(item: any, key: any) {
  // 인풋의 값을 컴마 없이 만듭니다.
  item[key] = unformatNumber(item[key]);

  // 값이 0이면 빈 값으로 설정하여 placeholder를 보이도록 합니다.
  if (item[key] === '0' || item[key] === 0 || item[key] === '-0') {
    item[key] = '';
  }
}

function applyFormat(item: any, key: any) {
  // 값이 비어있으면 0으로 설정
  if (item[key] === '' || item[key] === null || item[key] === undefined || item[key] === '-0') {
    item[key] = 0;
  } else {
    // 포커스가 해제되면 천 단위로 컴마를 추가
    item[key] = formatNumber(item[key]);
  }
}

function formatNumber(value: any): string {
  // 숫자인지 확인하고 천 단위로 컴마 추가
  const number = parseFloat(value);
  if (!isNaN(number)) {
    return number.toLocaleString();
  }
  return value;
}

function unformatNumber(value: any) {
  // 컴마를 제거
  return value.toString().replace(/,/g, '');
}

function nc(value: any) {
  // 컴마를 제거
  return parseFloat(value.toString().replace(/,/g, ''));
}

const readEmpAtt = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const response = await axios.get(`/api/readEmpAtt/${code.value}/${date.value}`);
    const responseData = response.data;
    if (responseData) {
      empAttList.value = responseData;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    await getEndEmpAtt();
    loading.value = false;
  }
};
watch(
  () => store.state.AdminYYMM,
  (newValue) => {
    if (newValue) {
      date.value = newValue
      readEmpAtt();
  }
}
);
const updateEmpAtt = async (item: any) => {
  if (loading.value) return;
  if(item.ea_work_day > 31){
    alert('기본근로일수를 확인해주세요.');
    return;
  }else if(item.ea_day_off > 31){
    alert('연차일수를 확인해주세요.');
    return;
  }else if(item.ea_work_time > 299){
    alert('기본근로시간을 확인해주세요.');
    return;
  }else if(item.ea_over_time > 99){
    alert('연장근로시간을 확인해주세요.');
    //일주일 최대 12시간 초과근무 = 12*4.345 = 한달 초과근무 최대시간
    return;
  }else if(item.ea_night_time > 299){
    alert('야간근로시간을 확인해주세요.');
    return;
  }else if(item.ea_holiday_time > 99){
    alert('휴일근로시간을 확인해주세요.');
    return;
  }
  // 1주 최대 근로시간은 법정근로시간 40시간, 연장근로 12시간, 휴일근로 16시간(사업장에 따라 휴일이 1일인 경우 8시간)이 가능하여 총 68시간(60시간) 근로가 가능하였습니다.
  const isConfirmed = window.confirm(`${item.emp_name}님의 정보를 수정하시겠습니까?`);
  if (isConfirmed) {
  loading.value = true;
  try {
    const response = await axios.post(`/api/updateEmpAtt/`, { form: item });
    const result = response.data;
    if (result.success === true) {
      console.log('수정완료')
    } else {
      alert('근무내역 수정에 실패하였습니다. 관리자에게 문의해주세요.');
    }
  } catch (error) {
    alert('근무내역 수정을 처리할 수 없습니다.');
    console.error(error);
  } finally {
    loading.value = false;
    item.isEditing = false;
  }
}else{
  return;
}
};


const updateAllEmpAtt = async () => {
  if (loading.value) return;
  empAttList.value.forEach((item) => {
    if (isNaN(nc(item.ea_work_day)) || nc(item.ea_work_day) > 31) {
      alert('기본근로일수를 확인해주세요.');
      return;
    }else if(isNaN(nc(item.ea_day_off)) || nc(item.ea_day_off) > 31){
      alert('연차일수를 확인해주세요.');
      return;
    }else if(isNaN(nc(item.ea_work_time)) || nc(item.ea_work_time) > 299){
      alert('기본근로시간을 확인해주세요.');
      return;
    }else if(isNaN(nc(item.ea_over_time)) || nc(item.ea_over_time) > 99){
      alert('연장근로시간을 확인해주세요.');
      //일주일 최대 12시간 초과근무 = 12*4.345 = 한달 초과근무 최대시간
      return;
    }else if(isNaN(nc(item.ea_night_time)) || nc(item.ea_night_time) > 299){
      alert('야간근로시간을 확인해주세요.');
      return;
    }else if(isNaN(nc(item.ea_holiday_time)) || nc(item.ea_holiday_time) > 99){
      alert('휴일근로시간을 확인해주세요.');
      return;
    }
  })

  // 1주 최대 근로시간은 법정근로시간 40시간, 연장근로 12시간, 휴일근로 16시간(사업장에 따라 휴일이 1일인 경우 8시간)이 가능하여 총 68시간(60시간) 근로가 가능하였습니다.
  const isConfirmed = window.confirm('모든 정보를 저장하시겠습니까?');
  if (isConfirmed) {
  loading.value = true;
  try {
    const response = await axios.post(`/api/updateAllEmpAtt/`, { form: empAttList.value });
    const result = response.data;
    if (result.success === true) {
      console.log('수정완료')
    } else {
      alert('저장에 실패하였습니다. 관리자에게 문의해주세요.');
    }
  } catch (error) {
    alert('저장할 수 없습니다. 입력된 정보를 확인해주세요.');
    console.error(error);
  } finally {
    loading.value = false;
    readEmpAtt();
  }
}else{
  return;
}
};
const deleteAllEmpAtt = async () => {
  try {
    const isConfirmed = window.confirm('모든 정보를 정말 삭제하시겠습니까?');
    if (isConfirmed) {
      const form = empAttList.value.map(item => item.emp_num);
      const response = await axios.post(`/api/deleteAllEmpAtt/${code.value}/${date.value}`,{form: form});
      const result = response.data;
      if (result.success === true) {
        await readEmpAtt();
        alert('삭제가 완료되었습니다.');
      } else {
        alert('삭제에 실패하였습니다. 관리자에게 문의해주세요.');
      }
    }else{
      return;
    }
  } catch (error) {
    alert('삭제작업을 처리할 수 없습니다.');
    console.error(error);
  }
};

const endEmpStatus = ref(false);
const getEndEmpAtt = async () => {
  try {
    const response = await axios.get(`/api/getEndEmpAtt/${code.value}/${date.value}`);
    const result = response.data;
    if (result.success === true) {
      endEmpStatus.value = false;
    } else {
      endEmpStatus.value = true;
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
function formatDate(dateString: any) {
  const [year, month] = dateString.split('-');
  return `${year}년 ${parseInt(month, 10)}월`;
}
const endEmpAtt = async () => {
  try {
    if(empAttList.value.length === 0){
      alert('임금기초를 작성해주세요.');
      return;
    }
    const isConfirmed = window.confirm(`${formatDate(date.value)} 임금기초를 마감하시겠습니까?`);
    if (isConfirmed) {
      const response = await axios.post(`/api/endEmpAtt/${code.value}/${date.value}`);
      const result = response.data;
    console.log(result,'result')

      if (result.success === true) {
        await readEmpAtt();
        alert('마감이 완료되었습니다.');
      } else {
        alert('마감에 실패하였습니다. 관리자에게 문의해주세요.');
      }
    }else{
      return;
    }
  } catch (error) {
    alert('작업을 처리할 수 없습니다.');
    console.error(error);
  }
};
const cancelEndEmpAtt = async () => {
  try {
    if(empAttList.value.length === 0){
      alert('임금기초를 작성해주세요.');
      return;
    }
    const isConfirmed = window.confirm(`${formatDate(date.value)} 임금기초 마감을 해제하시겠습니까?`);
    if (isConfirmed) {
      const response = await axios.post(`/api/cancelEndEmpAtt/${code.value}/${date.value}`);
      const result = response.data;
      if (result.success === true) {
        await readEmpAtt();
        alert('마감 해제가 완료되었습니다.');
      } else {
        alert('마감 해제를 실패하였습니다. 관리자에게 문의해주세요.');
      }
    }else{
      return;
    }
  } catch (error) {
    alert('작업을 처리할 수 없습니다.');
    console.error(error);
  }
};

onMounted(async () => {
  await readEmpAtt();
})
onUnmounted(() => {
  loading.value = false;
});

</script>