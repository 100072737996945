<template>
  <v-icon @click="deleteEmpAtt()" color="blue-grey-darken-2" class="SsDeleteIcon">{{ mdiDelete }}</v-icon> 

</template>

<script setup lang="ts">
import axios from 'axios';
import {
  mdiDelete
} from '@mdi/js'
import { defineProps } from 'vue';
// eslint-disable-next-line no-undef
const emit = defineEmits(['success'])
const props = defineProps({
  item: {
    type: Object,
    required: true,
  }
});
const deleteEmpAtt = async () => {
  try {
    const isConfirmed = window.confirm('정말 삭제하시겠습니까?');
    if (isConfirmed) {
      const code = props.item.sisul_code;
      const empNum = props.item.emp_num;
      const eaYm = props.item.ea_ym;

      const response = await axios.post(`/api/deleteEmpAtt/${code}/${empNum}/${eaYm}`);
      const result = response.data;
      if (result.success === true) {
        alert('삭제가 완료되었습니다.');
        emit('success');
      } else {
        alert('삭제에 실패하였습니다. 관리자에게 문의해주세요.');
      }
    }else{
      return;
    }
  } catch (error) {
    alert('삭제작업을 처리할 수 없습니다.');
    console.error(error);
  }
};
</script>