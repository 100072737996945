<template>
  <span>사원정보삭제</span>
  <v-icon
    @click="deleteEmp()"
    color="blue-grey-darken-2"
    size="large"
    style="padding-bottom: 3px"
    >{{ mdiDelete }}</v-icon
  >
</template>

<script setup lang="ts">
import axios from "axios";
import { mdiDelete } from "@mdi/js";
import { defineProps } from "vue";
// eslint-disable-next-line no-undef
const emit = defineEmits(["success"]);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});
const deleteEmp = async () => {
  try {
    const isConfirmed = window.confirm(
      "정말 삭제하시겠습니까? 삭제 시 퇴사자 반영이 되지 않습니다."
    );
    if (isConfirmed) {
      const code = props.item.sisul_code;
      const empNum = props.item.emp_num;
      const response = await axios.post(`/api/deleteEmp/${code}/${empNum}`);
      const result = response.data;
      if (result.success === true) {
        alert("사원정보 삭제가 완료되었습니다.");
        emit("success");
      } else {
        alert("사원정보 삭제에 실패하였습니다. 관리자에게 문의해주세요.");
      }
    } else {
      return;
    }
  } catch (error) {
    alert("사원정보 삭제작업을 처리할 수 없습니다.");
    console.error(error);
  }
};
</script>
