<template>
  <template v-if="loading">
    <AdminLoadingAction />
  </template>
  <template v-else>
    <v-data-table
      no-data-text="데이터가 존재하지 않습니다." 
      fixed-header
      density="compact"
      scrollable
      :headers="headerCateList"
      :items="filteredItems"
      :search="search"
      item-value="name"
      class="nonTbFooter empWageCalcTb adminDataTb"
      :items-per-page="-1"
      :items-per-page-options="[
        // { value: 50, title: '50' },
        // { value: 100, title: '100' },
        // { value: 200, title: '200' }
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
      ]"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="fontNotoSans700" style="max-width: 160px"
            >🧑‍💼 임금대장</v-toolbar-title
          >
          <h3><FormatMonth /></h3>

          <v-toolbar-title class="fontNotoSans700"> </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <WageLedgerPayDate @success="empWageCalc()" />
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-btn class="btnColorDark" v-bind="props"> 임금보고서 ▾ </v-btn>
            </template>
            <v-list>
              <v-list-item @click="getWagePdfPreview">
                <p class="fontNotoSans500">임금명세서.pdf</p>
              </v-list-item>
              <v-list-item @click="getWageExcel">
                <p class="fontNotoSans500">임금대장(고객용).xlsx</p>
              </v-list-item>
              <v-list-item @click="getWageExcelAdmin" v-if="store.getters.role >= 8">
                <p class="fontNotoSans500">임금대장(관리자용).xlsx</p>
              </v-list-item>
              <v-list-item @click="getWageExcelFnA" v-if="store.getters.role >= 8">
                <p class="fontNotoSans500">임금대장(회계처리용).xlsx</p>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field
            class="inoutSearchInput"
            style="
              background-color: #fff;
              max-width: 200px;
              width: auto;
              min-width: 200px;
              margin: 0 20px 0 0;
            "
            v-model="search"
            color="#0f7545"
            label="이름/직위 검색"
            single-line
            clearable
            :append-inner-icon="mdiMagnify"
            flat
            hide-details="auto"
            variant="outlined"
            density="compact"
          >
          </v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item, index }">
        <tr class="empWageCalcTbTr">
          <td>
            {{ index + 1 || 0 }}
          </td>
          <td>
            <v-chip v-if="item.ym === item.empOutDate" color="dark" style="padding:4px; height:20px;">퇴</v-chip>
            <v-chip v-if="item.ym === item.empInDate" color="dark" style="padding:4px; height:20px;">입</v-chip>
            {{ item.empName || 0 }}
          </td>
          
          <td class="d-flex align-center justify-space-between">
            <span class="text-center" style="flex: 1">{{ item.empJob || 0 }}</span>
            <p v-if="item.ew_manual === '1'" style="background-color: rgb(255, 230, 86); border-radius: 10px;">
              <WageLedgerManualModal @success="empWageCalc()" :item="item" :soji="soji" :tax="tax"/>
            </p>
            <p v-else>
              <WageLedgerManualModal @success="empWageCalc()" :item="item" :soji="soji" :tax="tax"/>
            </p>
          </td>
          <td>
            {{ item.base || 0 }}
          </td>
          <td>
            {{ item.overtime || 0 }}
          </td>
          <td>
            {{ item.night || 0 }}
          </td>
          <td>
            {{ item.holiday || 0 }}
          </td>
          <td>
            {{ item.dayOffPay || 0 }}
          </td>
          <td>
            {{ item.longTerm || 0 }}
          </td>
          <td>
            {{ item.bonus || 0 }}
          </td>
          <td>
            {{ item.eduPay || 0 }}
          </td>
          <td>
            {{ item.bojon || 0 }}
          </td>
          <td>
            {{ item.etc || 0 }}
          </td>
          <td>
            {{ item.lunch || 0 }}
          </td>
          <td>
            {{ item.car || 0 }}
          </td>
          <td>
            {{ item.childcare || 0 }}
          </td>
          <td style="font-weight: 700">
            {{ item.total || 0 }}
          </td>
          <td>
            {{ item.taxable || 0 }}
          </td>
          <td>
            {{ item.usual || 0 }}
          </td>
          <td>
            {{ item.minimum || 0 }}
          </td>
          <td>
            {{ item.l_yeon || 0 }}
          </td>
          <td>
            {{ item.l_gun || 0 }}
          </td>
          <td>
            {{ item.l_jang || 0 }}
          </td>
          <td>
            {{ item.l_go || 0 }}
          </td>
          <td>
            {{ item.l_so || 0 }}
          </td>
          <td>
            {{ item.l_ji || 0 }}
          </td>
          <td>
            {{ item.l_jiwon || 0 }}
          </td>
          <td>
            {{ item.l_sik || 0 }}
          </td>
          <td>
            {{ item.l_etc || 0 }}
          </td>
          <td>
            {{ item.l_yearEnd || 0 }}
          </td>
          <td style="font-weight: 700">
            {{ item.l_total || 0 }}
          </td>
          <td>
            {{ item.c_yeon || 0 }}
          </td>
          <td>
            {{ item.c_gun || 0 }}
          </td>
          <td>
            {{ item.c_jang || 0 }}
          </td>
          <td>
            {{ item.c_go || 0 }}
          </td>
          <td>
            {{ item.c_sil || 0 }}
          </td>
          <td>
            {{ item.c_san || 0 }}
          </td>
          <td>
            {{ item.c_out || 0 }}
          </td>
          <td style="font-weight: 700">
            {{ item.c_total || 0 }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </template>
</template>
<script setup lang="ts">
import AdminLoadingAction from '@/components/admin/layout/AdminLoadingAction.vue'

import WageLedgerPayDate from "@/components/admin/services/wage/ledger/WageLedgerPayDate.vue";
import WageLedgerManualModal from "@/components/admin/services/wage/ledger/WageLedgerManualModal.vue";

import FormatMonth from '@/components/admin/common/datePicker/FormatMonth.vue'

import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import axios from "axios";
import store from "@/store";
import { mdiMagnify } from "@mdi/js";

const wageList = ref([] as any[]);
const loading = ref(false);
const code = ref(store.state.AdminSisulCode) as any;
const bizGubun = ref(store.state.AdminBizGubun) as any;
const sisulName = ref(store.state.AdminSisulName) as any;

const search = ref("");
const date = ref(store.state.AdminYYMM);
const headerCateList = ref([
  {
    title: "사원구분",
    align: "center",
    children: [
      { title: "순번", key: "index", align: "center", sortable: false },
      { title: "이름", key: "empName", align: "center", sortable: false },
      { title: "직종", key: "empJob", align: "center", sortable: false },
    ],
  },
  {
    title: "임금구성",
    align: "center",
    children: [
      { title: "기본급", key: "base", align: "center", sortable: false },
      { title: "연장수당", key: "overtime", align: "center", sortable: false },
      { title: "야간수당", key: "night", align: "center", sortable: false },
      { title: "휴일수당", key: "holiday", align: "center", sortable: false },
      { title: "연차수당", key: "dayOffPay", align: "center", sortable: false },
      { title: "장기근속", key: "longTerm", align: "center", sortable: false },
      { title: "상여금", key: "bonus", align: "center", sortable: false },
      { title: "교육비", key: "eduPay", align: "center", sortable: false },
      { title: "보존수당", key: "bojon", align: "center", sortable: false },
      { title: "기타수당", key: "etc", align: "center", sortable: false },
      { title: "중식대", key: "lunch", align: "center", sortable: false },
      { title: "차량비", key: "car", align: "center", sortable: false },
      { title: "양육비", key: "childcare", align: "center", sortable: false },
      { title: "임금합계", key: "total", align: "center", sortable: false },
    ],
  },
  {
    title: "조정내역",
    align: "center",
    children: [
      { title: "과세임금", key: "taxable", align: "center", sortable: false },
      { title: "통상임금", key: "usual", align: "center", sortable: false },
      { title: "최저임금", key: "minimum", align: "center", sortable: false },
    ],
  },
  {
    title: "근로자 공제",
    align: "center",
    children: [
      { title: "연 금", key: "", align: "center", sortable: false },
      { title: "건 강", key: "", align: "center", sortable: false },
      { title: "장 기", key: "", align: "center", sortable: false },
      { title: "고 용", key: "", align: "center", sortable: false },
      { title: "소득세", key: "", align: "center", sortable: false },
      { title: "지방세", key: "", align: "center", sortable: false },
      { title: "지원공제", key: "", align: "center", sortable: false },
      { title: "식비공제", key: "", align: "center", sortable: false },
      { title: "기타공제", key: "", align: "center", sortable: false },
      { title: "연말정산", key: "", align: "center", sortable: false },
      { title: "합 계", key: "", align: "center", sortable: false },
    ],
  },
  {
    title: "기관 공제",
    align: "center",
    children: [
      { title: "연 금", key: "", align: "center", sortable: false },
      { title: "건 강", key: "", align: "center", sortable: false },
      { title: "장 기", key: "", align: "center", sortable: false },
      { title: "고 용", key: "", align: "center", sortable: false },
      { title: "실 업", key: "", align: "center", sortable: false },
      { title: "산 재", key: "", align: "center", sortable: false },
      { title: "퇴직금", key: "", align: "center", sortable: false },
      { title: "합 계", key: "", align: "center", sortable: false },
    ],
  },
] as any[]);

const filteredItems = computed(() => {
  if (!search.value) {
    return wageList.value;
  }
  return wageList.value.filter((item) => {
    const matchesSearch =
      (item.empName && item.empName.toLowerCase().includes(search.value.toLowerCase())) ||
      (item.empJob && item.empJob.toLowerCase().includes(search.value.toLowerCase()));
    return matchesSearch;
  });
});
watch(
  () => store.state.AdminYYMM,
  (newValue) => {
    if (newValue) {
      date.value = newValue;
      empWageCalc();
    }
  }
);
const tax = ref([] as any);
const soji = ref([] as any);

const empWageCalc = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const response = await axios.get(`/api/empWageCalc/${code.value}/${date.value}/${bizGubun.value}/${sisulName.value}`);
    const responseData = response.data;
    if (responseData) {
      wageList.value = responseData.result;
      soji.value = responseData.soji;
      tax.value = responseData.tax;
      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value = false;
  }
};
const getWageExcel = async () => {
  try {
    const response = await axios.post(
      "/api/getWageExcel",
      { wageList: wageList.value },
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${wageList.value[0].sisulName}_임금대장_${wageList.value[0].ym}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Excel 파일 다운로드 중 에러 발생:", error);
  }
};

const getWageExcelAdmin = async () => {
  try {
    const response = await axios.post(
      "/api/getWageExcelAdmin",
      { wageList: wageList.value },
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${wageList.value[0].sisulName}_임금대장(관리자용)_${wageList.value[0].ym}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Excel 파일 다운로드 중 에러 발생:", error);
  }
};

const getWageExcelFnA = async () => {
  try {
    const response = await axios.post(
      "/api/getWageExcelFnA",
      { wageList: wageList.value },
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${wageList.value[0].sisulName}_임금대장(회계처리용)_${wageList.value[0].ym}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Excel 파일 다운로드 중 에러 발생:", error);
  }
};
const getWagePdfPreview = async () => {
  try {
    // 서버로부터 PDF 데이터를 요청
    const response = await axios.post(
      "/api/getWagePdfPreview",
      { wageList: wageList.value },
      {
        responseType: "json",
      }
    );

    // 서버로부터 받은 데이터에서 PDF Base64 문자열 추출
    const pdfBase64 = response.data.pdf;

    // Base64 문자열을 이진 데이터로 변환
    const binaryString = window.atob(pdfBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // 이진 데이터로부터 Blob 생성
    const blob = new Blob([bytes], { type: "application/pdf" });

    // Blob URL 생성
    const url = window.URL.createObjectURL(blob);

    // 새 창에서 PDF 열기
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      console.error("새 창을 열 수 없습니다. 팝업 차단 설정을 확인하세요.");
    }
  } catch (error) {
    console.error("PDF 미리보기 중 에러 발생:", error);
  }
};

onMounted(async () => {
  await empWageCalc();
});
onUnmounted(() => {
  loading.value = false;
});
</script>
