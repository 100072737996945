<template>
  <div style="display: flex; align-items: center; justify-content: center;">
    <v-icon color="#002e00" @click="previousMonth" size="48">{{ mdiChevronLeft }}</v-icon>
    <v-menu :close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <h2 class="pb-1" style="cursor: pointer; color: #333;" v-bind="props">{{ currentMonthDisplay }}</h2>
      </template>
      <v-card style="width: 300px;">
        <v-card-title style="display: flex; align-items: center; justify-content: center;">
          <v-icon color="#002e00" @click="previousYear" size="48" class="pt-1">{{ mdiChevronLeft }}</v-icon>
          <h2 class="text-center">{{ currentYear }}</h2>
          <v-icon color="#002e00" @click="nextYear" size="48" class="pt-1">{{ mdiChevronRight }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="pa-4" justify="center">
            <v-col v-for="month in months" :key="month.value" cols="3" class="d-flex justify-center">
              <v-btn
                class="monthLabelBtn"
                :class="{'btnBg': month.value === currentMonth, 'ma-1': true}"
                @click="selectMonth(month)"
                :disabled="month.value === currentMonth"
              >
                {{ month.label }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-icon color="#002e00" @click="nextMonth" size="48">{{ mdiChevronRight }}</v-icon>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { format, parse, addYears, addMonths, getYear, setMonth } from 'date-fns';
import store from '../../../../store';
const months = [
  { label: '1월', value: '01' },
  { label: '2월', value: '02' },
  { label: '3월', value: '03' },
  { label: '4월', value: '04' },
  { label: '5월', value: '05' },
  { label: '6월', value: '06' },
  { label: '7월', value: '07' },
  { label: '8월', value: '08' },
  { label: '9월', value: '09' },
  { label: '10월', value: '10' },
  { label: '11월', value: '11' },
  { label: '12월', value: '12' },
];

const currentDate = ref(parse(store.state.AdminYYMM, 'yyyy-MM', new Date()));

const currentYear = computed(() => getYear(currentDate.value));
const currentMonth = computed(() => format(currentDate.value, 'MM'));
const currentMonthDisplay = computed(() => format(currentDate.value, 'yyyy-MM'));

const previousMonth = () => {
  currentDate.value = addMonths(currentDate.value, -1);
  updateStore();
};

const nextMonth = () => {
  currentDate.value = addMonths(currentDate.value, 1);
  updateStore();
};

const previousYear = () => {
  currentDate.value = addYears(currentDate.value, -1);
  updateStore();
};

const nextYear = () => {
  currentDate.value = addYears(currentDate.value, 1);
  updateStore();
};

const selectMonth = (month: any) => {
  const monthIndex = parseInt(month.value, 10) - 1;
  currentDate.value = setMonth(currentDate.value, monthIndex);
  updateStore();
};

const updateStore = () => {
  store.commit('setAdminYYMM', format(currentDate.value, 'yyyy-MM'));
};

watch(
  () => store.state.AdminYYMM,
  (newValue) => {
    if (newValue) {
      currentDate.value = parse(newValue, 'yyyy-MM', new Date());
    }
  }
);
</script>

<style scoped>
.monthLabelBtn {
  background-color: #fff;
  color: #333;
  border: 1px solid rgb(203, 203, 203);
  font-size: 16px;
  min-width: 50px !important;
  max-height: 40px !important;
}
.btnBg {
  background-color: #002e00 !important;
  color: #fff !important;
}
</style>
