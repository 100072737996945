<template>
  <span class="mr-2" style="font-size: 14px;">지급일 | </span>
  <input v-if="store.state.role > 3" type="date" color="indigo"  style="font-size: 14px;" v-model="payDate" min="2000-01-01" max="2099-12-31" />
  <input v-else readonly type="date" color="indigo"  style="font-size: 14px;" v-model="payDate" min="2000-01-01" max="2099-12-31" />
  <v-btn @click="setPayDate" class="btnColorPurple ml-2" v-if="store.state.role > 3">저장</v-btn>
</template>
<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { onMounted, ref } from 'vue';
const payDate = ref(null)
const code = ref(store.state.AdminSisulCode) as any;

// eslint-disable-next-line no-undef
const emit = defineEmits(['success'])
const getPayDate = async () => {
  try {
    const response = await axios.get(`/api/getPayDate/${code.value}/${store.state.AdminYYMM}`);
    const responseData = response.data;
    if (responseData) {
      payDate.value = responseData;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

const setPayDate = async () => {
  try {
    const response = await axios.post(`/api/setPayDate/${payDate.value}/${code.value}/${store.state.AdminYYMM}`);
    const result = response.data;
    if (result.success === true) {
      alert('지급일이 저장되었습니다.');
      emit('success');
    } else {
      alert('실패하였습니다. 관리자에게 문의해주세요.');
    }
  } catch (error) {
    alert('처리할 수 없습니다.');
    console.error(error);
  }
};
onMounted(async() => {
 await getPayDate();
})
</script>