<template>
  <v-btn @click="logout" :append-icon="mdiLogoutVariant">
     종료
    </v-btn>
</template>
<script setup>

import {
mdiLogoutVariant,
} from '@mdi/js';
const logout = () => {
window.close();
};
</script>