<template>
  <div>
    <canvas ref="chartCanvas" class="dashboardCanvas"></canvas>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Chart, registerables } from "chart.js";

// Chart.js의 모든 모듈 등록
Chart.register(...registerables);

const chartCanvas = ref<HTMLCanvasElement | null>(null);

// 데이터: 월별 입사자와 퇴사자 수
const data = {
  months: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
  entrants: [5, 8, 6, 7, 10, 9, 15, 12, 11, 14, 10, 13], // 입사자 수
  leavers: [3, 5, 4, 6, 8, 7, 10, 9, 8, 12, 6, 10], // 퇴사자 수
};

const drawChart = () => {
  const canvas = chartCanvas.value;
  if (!canvas) return;

  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  // Chart.js를 사용하여 차트 생성
  new Chart(ctx, {
    type: 'line', // 차트 유형
    data: {
      labels: data.months, // X축 레이블
      datasets: [
        {
          label: '입사자 수',
          data: data.entrants,
          borderColor: '#42b3f4', // 입사자 색상
          backgroundColor: 'rgba(66, 179, 244, 0.2)', // 입사자 배경 색상
          fill: true, // 면적 채우기
          tension: 0.4, // 곡선의 부드러움
          pointBackgroundColor: '#42b3f4', // 입사자 데이터 포인트 색상
          pointRadius: 5, // 데이터 포인트 크기
        },
        {
          label: '퇴사자 수',
          data: data.leavers,
          borderColor: '#f72047', // 퇴사자 색상
          backgroundColor: 'rgba(247, 32, 71, 0.2)', // 퇴사자 배경 색상
          fill: true, // 면적 채우기
          tension: 0.4, // 곡선의 부드러움
          pointBackgroundColor: '#f72047', // 퇴사자 데이터 포인트 색상
          pointRadius: 5, // 데이터 포인트 크기
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            font: {
              size: 12,
              weight: 'bold',
            },
          },
        },
        title: {
          display: true,
          text: '2024년 입사자 및 퇴사자 수',
          font: {
            size: 16,
            weight: 'bold',
          },
          padding: {
            top: 10,
            bottom: 0,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true, // Y축 0부터 시작
        },
      },
    },
  });
};

// 컴포넌트가 마운트된 후 그래프를 그림
onMounted(() => {
  drawChart();
});
</script>
