<template>
  <v-data-table
    no-data-text="데이터가 존재하지 않습니다."
    fixed-header
    density="compact"
    scrollable
    :headers="headersArr"
    :items="filteredItems"
    :search="search"
    item-value="name"
    class="fontNotoSans400"
    style="
      height: calc(100vh - 217px);

      overflow-y: hidden;
      position: relative;
    "
    :items-per-page="50"
    :items-per-page-options="[
      { value: 50, title: '50' },
      { value: 100, title: '100' },
      { value: 200, title: '200' },
      // { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
    ]"
    v-if="sisulList.length !== 0"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="fontNotoSans700">🏢 고객관리</v-toolbar-title>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          class="inoutSearchInput"
          style="
            background-color: #fff;
            max-width: 200px;
            width: auto;
            min-width: 70px;
            margin: 0 20px 0 0;
          "
          v-model="search"
          color="#0f7545"
          label="고객기관, 주소 검색"
          single-line
          clearable
          :append-inner-icon="mdiMagnify"
          flat
          hide-details="auto"
          variant="outlined"
          density="compact"
        >
        </v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item="{ item, index }">
      <tr class="datatableTr">
        <td class="tac">{{ index + 1 }}</td>
        <td class="">
          <v-chip
            @click="selectSisul(item)"
            variant="text"
            label
            class="ma-2"
            color="black"
          >
            <v-chip
              class="tar"
              color="orange "
              label
              v-if="item.sisul_biz_gubun === '주간보호'"
            >
              주간
            </v-chip>
            <v-chip
              class="tar"
              color="blue"
              label
              v-else-if="item.sisul_biz_gubun === '요양시설'"
            >
              요양
            </v-chip>
            <v-chip class="tar" color="blue" label v-else> </v-chip>
            <span style="margin-left: 5px">{{ item.sisul_name || "" }}</span>
          </v-chip>
        </td>
        <td class="tac">
          {{ item.sisul_boss_name }} / {{ item.sisul_boss_phone }}<br />{{
            item.sisul_boss_email
          }}
        </td>
        <td class="tac">
          {{ item.sisul_since }}
        </td>
        <td class="tac">{{ item.sisul_hyunwon }}/{{ item.sisul_jungwon }}</td>
        <td class="tac">
          {{ item.sisul_small_addr }}
        </td>
        <td class="tac">
          <template v-if="!item.editing">
            <v-chip color="red-accent-4 " v-if="item.sisul_role === 0">
              {{ item.role_name }}
            </v-chip>
            <v-chip color="blue-accent-4" v-else-if="item.sisul_role === 3">
              {{ item.role_name }}
            </v-chip>
          </template>
          <template v-else>
            <select
              v-model="item.sisul_role"
              style="outline: auto; padding: 3px 20px 3px 20px"
            >
              <option
                v-for="option in SsRoleOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </template>
        </td>
        <td class="tac">
          <template v-if="!item.editing">
            <v-chip color="black" v-if="item.sisul_status === 0">
              {{ item.status_name }}
            </v-chip>
            <v-chip color="green-darken-2" v-else-if="item.sisul_status === 1">
              {{ item.status_name }}
            </v-chip>
            <v-chip color="orange-accent-4" v-else-if="item.sisul_status === 2">
              {{ item.status_name }}
            </v-chip>
            <v-chip color="yellow-darken-1" v-else-if="item.sisul_status === 3">
              {{ item.status_name }}
            </v-chip>
          </template>
          <template v-else>
            <select
              v-model="item.sisul_status"
              style="outline: auto; padding: 3px 20px 3px 20px"
            >
              <option
                v-for="status in SsStatusOptions"
                :key="status.value"
                :value="status.value"
              >
                {{ status.label }}
              </option>
            </select>
          </template>
        </td>
        <td class="tac">
          <template v-if="!item.editing">
            <p v-if="item.sisul_wage_in">임금: {{ item.sisul_wage_in }}</p>
            <p v-if="item.sisul_fna_in">회계: {{ item.sisul_fna_in }}</p>
            <p v-if="item.sisul_meal_in">급식: {{ item.sisul_meal_in }}</p>
          </template>
          <template v-else>
            임금:
            <input
              type="date"
              style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px"
              :value="item.sisul_wage_in"
              v-model="item.sisul_wage_in"
            />
            <br />
            회계:
            <input
              type="date"
              style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px"
              v-model="item.sisul_fna_in"
            />
            <br />
            급식:
            <input
              type="date"
              style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px"
              v-model="item.sisul_meal_in"
            />
          </template>
        </td>
        <td class="tac">
          <template v-if="!item.editing">
            <p v-if="item.sisul_wage_out">임금: {{ item.sisul_wage_out }}</p>
            <p v-if="item.sisul_fna_out">회계: {{ item.sisul_fna_out }}</p>
            <p v-if="item.sisul_meal_out">급식: {{ item.sisul_meal_out }}</p>
          </template>
          <template v-else>
            임금:
            <input
              type="date"
              style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px"
              v-model="item.sisul_wage_out"
            />
            <br />
            회계:
            <input
              type="date"
              style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px"
              v-model="item.sisul_fna_out"
            />
            <br />
            급식:
            <input
              type="date"
              style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px"
              v-model="item.sisul_meal_out"
            />
          </template>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script setup lang="ts">
import store from "@/store";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { mdiMagnify } from "@mdi/js";
import router from "@/router";
// import { format } from "date-fns";
const sisulList = ref([] as any[]);
const search = ref("");
const headersArr = ref([
  { title: "No.", key: "index", align: "center", sortable: false },
  { title: "기관명", key: "sisul_name", align: "center", sortable: true },
  { title: "대표자", key: "sisul_boss_name", align: "center", sortable: true },
  { title: "설립일", key: "sisul_since", align: "center", sortable: true },
  { title: "수급자", key: "sisul_jungwon", align: "center", sortable: true },
  { title: "주소", key: "sisul_addr", align: "center", sortable: true },
  { title: "인증", key: "sisul_role", align: "center", sortable: true },
  { title: "상태", key: "sisul_status", align: "center", sortable: true },
  { title: "서비스시작일", key: "sisul_wage_in", align: "center", sortable: true },
  { title: "서비스탈퇴일", key: "sisul_wage_out", align: "center", sortable: true },
] as any[]);
const SsRoleOptions = ref([
  { label: "미인증", value: 0 },
  { label: "인증", value: 3 },
]);
const SsStatusOptions = ref([
  { label: "탈퇴", value: "0" },
  { label: "정상", value: "1" },
  { label: "휴직", value: "2" },
  { label: "경고", value: "3" },
]);
const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return sisulList.value;
  }
  return sisulList.value.filter(
    (item) =>
      item.sisul_name &&
      item.sisul_name.toLowerCase().includes(search.value.toLowerCase())
  );
});
const selectSisul = (item: any) => {
  const sisul = `${item.sisul_name}(${item.sisul_code})`;
  const bizGubun = item.sisul_biz_gubun;
  let service = "근태관리";
  let goto = "WorkMain";
  // const today = format(new Date(), "yyyy-MM-dd");
  // 만약, 근태관리도 조건에 맞춰야한다면 다시 활성화 시켜야함.
  // if (
  //   item.sisul_wage_in <= today &&
  //   (!item.sisul_wage_out || item.sisul_wage_out >= today)
  // ) {
  //   service = "임금관리";
  //   goto = "WageMain";
  // }else if (
  //   item.sisul_fna_in <= today &&
  //   (!item.sisul_fna_out || item.sisul_fna_out >= today)
  // ) {
  //   service = "재무회계";
  //   goto = "FnaMain";
  // }else if (
  //   item.sisul_meal_in <= today &&
  //   (!item.sisul_meal_out || item.sisul_meal_out >= today)
  // ) {
  //   service = "위탁급식";
  //   goto = "MealMain";
  // }else {
  //   service = "근태관리";
  //   goto = "WorkMain";
  // }
  store.commit("setAdminSisul", sisul);
  store.commit("setAdminSisulCode", item.sisul_code);
  store.commit("setAdminSisulName", item.sisul_name);
  store.commit("setAdminBizGubun", bizGubun);
  store.commit("setAdminService", service);
  router.push(`/${goto}`);
};

const readSisul = async () => {
  try {
    const response = await axios.get(`/api/readSs`);
    const responseData = response.data;
    if (responseData) {
      sisulList.value = responseData;
      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};
onMounted(async () => {
  await readSisul();
});
</script>
