<template>
  <div>
    <canvas ref="chartCanvas" class="dashboardCanvas"></canvas>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Chart, registerables } from "chart.js";

// Chart.js의 모든 모듈 등록
Chart.register(...registerables);

const chartCanvas = ref<HTMLCanvasElement | null>(null);

// 데이터: 직업별 입사자 및 퇴사자 수치
const data = {
  labels: [
    '시설장',
    '사회복지사',
    '간호사',
    '간호조무사',
    '물리치료사',
    '사무장',
    '사무원',
    '영양사',
    '조리사',
    '조리원',
    '위생원',
    '요양보호사',
    '운전사',
    '기타'
  ],
  hires: [10, 15, 20, 5, 8, 12, 7, 9, 6, 4, 3, 11, 2, 1], // 입사자 수 (예시)
  departures: [2, 3, 1, 0, 1, 2, 0, 1, 0, 0, 1, 0, 0, 0] // 퇴사자 수 (예시)
};

// 그래프를 그리는 함수
const drawChart = () => {
  const canvas = chartCanvas.value;
  if (!canvas) return;

  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  // Chart.js를 사용하여 막대 차트 생성
  new Chart(ctx, {
    type: 'bar', // 차트 유형
    data: {
      labels: data.labels, // 레이블
      datasets: [
        {
          label: '입사자 수',
          data: data.hires, // 입사자 수 데이터
          backgroundColor: 'rgba(66, 179, 244, 0.6)', // 색상
        },
        {
          label: '퇴사자 수',
          data: data.departures, // 퇴사자 수 데이터
          backgroundColor: 'rgba(247, 32, 71, 0.6)', // 색상
        }
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top', // 범례 위치
          display: true,
          labels: {
            font: {
              size: 12,
              weight: 'bold',
            },
          },
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`; // 툴팁 포맷
            },
          },
        },
        title: {
          display: true,
          text: '1월 직종 별 입.퇴사자 수',
          font: {
            size: 16,
            weight: 'bold',
          },
          padding: {
            top: 10,
            bottom: 0,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true, // Y축 0부터 시작
        },
      },
    },
  });
};

// 컴포넌트가 마운트된 후 그래프를 그림
onMounted(() => {
  drawChart();
});
</script>
